import { SystemStyleObject } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { useNavigate } from 'react-router-dom';

import { ReadiedOrderSummary } from '@/api/gateway-click-collect/orders';
import { useCancelActionMutation } from '@/api/gateway-click-collect/terminals';
import { BackButton } from '@/components/BackButton';

export interface CancelPaymentButtonProps {
  locationUuid: string;
  order?: ReadiedOrderSummary;
  restaurantPlatformId: string;
  sx?: SystemStyleObject;
  terminalUuid: string;
}

export const CancelPaymentButton = ({
  locationUuid,
  order,
  restaurantPlatformId,
  sx,
  terminalUuid,
}: CancelPaymentButtonProps) => {
  const navigate = useNavigate();
  const { isPending, mutate } = useCancelActionMutation();

  const cancelPayment = () => {
    if (order) {
      mutate(
        {
          locationUuid,
          terminalUuid,
        },
        {
          onSettled: () => {
            navigate(`/onSite/${restaurantPlatformId}/customerName`);
          },
        }
      );
    } else {
      navigate(`/onSite/${restaurantPlatformId}/customerName`);
    }
  };

  return (
    <BackButton
      isLoading={isPending}
      label={<Trans>Back</Trans>}
      loadingText={<Trans>Back</Trans>}
      onClick={cancelPayment}
      sx={sx}
    />
  );
};
