import { t } from '@lingui/macro';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';

import { useConceptsConfigurationQuery, useRestaurantsQuery } from '@/api/gateway-click-collect';
import { useDeliveryPlatformsQuery } from '@/api/gateway-click-collect/locations';
import PageLoader from '@/components/PageLoader';
import { PendingOrderButton } from '@/components/PendingOrderButton';
import { MINUTE_MS } from '@/constants';
import usePageViewTracker from '@/hooks/usePageViewTracker';
import { useUpdateMetadata } from '@/hooks/useUpdateMetadata';
import { AppLayout } from '@/layouts/AppLayout';

import { RestaurantsList } from './RestaurantsList';

const FIVE_MINUTES_REFETCH_INTERVAL = MINUTE_MS * 5;

export const HomePage = () => {
  const { locationUuid } = useParams() as { locationUuid: string };
  const [searchParams] = useSearchParams();
  const isDelivery = !!searchParams.get('delivery');

  const {
    data: restaurantsList = [],
    isPending: isPendingRestaurants,
    error: areRestaurantsOnError,
  } = useRestaurantsQuery({
    requestParams: {
      locationUuid,
      withOpeningInformation: true,
    },
    options: {
      refetchInterval: FIVE_MINUTES_REFETCH_INTERVAL,
    },
  });

  const { data: deliveryPlatform, isLoading: isDeliveryPlatformPending } = useDeliveryPlatformsQuery({
    requestParams: {
      locationUuid,
    },
    options: {
      enabled: isDelivery,
    },
  });

  const {
    data: conceptsInformations = [],
    isPending: areConceptsPending,
    isError: areConceptsOnError,
  } = useConceptsConfigurationQuery({
    options: {
      gcTime: Infinity,
      staleTime: Infinity,
      select: ({ concepts }) => concepts,
    },
  });

  const kitchenLabel = restaurantsList?.[0]?.kitchenLabel;

  useUpdateMetadata({ title: kitchenLabel });
  usePageViewTracker(kitchenLabel);

  const isError = areRestaurantsOnError || areConceptsOnError;
  const isPending = isDeliveryPlatformPending || isPendingRestaurants || areConceptsPending;

  if (isError) {
    return <Navigate to="notFound" />;
  }

  if (isPending) {
    return <PageLoader />;
  }

  return (
    <AppLayout
      title={t`Our restaurants`}
      showLogo
      maxWidthBreakpoints={{ lg: '900px' }}
      showWebviewWarning
      url={'/'}
      hasArrowBack
    >
      <RestaurantsList
        conceptsInformations={conceptsInformations}
        restaurants={restaurantsList}
        deliveryPlatformsMap={deliveryPlatform?.deliveryRestaurants}
        isDelivery={isDelivery}
        locationUuid={locationUuid}
      />
      <PendingOrderButton />
    </AppLayout>
  );
};
