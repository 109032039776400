import { Button, Flex, Grid, IconButton, Image, Spinner, Text } from '@chakra-ui/react';
import { t, Trans } from '@lingui/macro';
import { MouseEventHandler, useState } from 'react';
import { Link } from 'react-router-dom';

import { CouponInfoErrorTypeEnum } from '@/api/customer-order/orders';
import { useValidateOrderMutation } from '@/api/gateway-click-collect/orders/useValidateOrderMutation';
import { CouponError } from '@/api/types';
import ArrowLeft from '@/assets/img/arrow-left.svg';
import { CouponForm } from '@/components/CouponForm';
import { useCart } from '@/contexts/cart';
import { formatCartForValidation } from '@/helpers/cart.helpers';
import useFormatPrice from '@/hooks/useFormatPrice';

const PROMO_AVAILABLE_COUNTRIES = new Set([]);

export interface CartPageFooterProps {
  currencyCode: string;
  languageCode: string;
  hasOutOfStocks?: boolean;
  isError: boolean;
  isLoading: boolean;
  onPaymentClick: MouseEventHandler<HTMLButtonElement>;
  price: number;
  restaurantPlatformId: string;
}

export const CartPageFooter = ({
  currencyCode,
  hasOutOfStocks = false,
  isError,
  languageCode,
  isLoading,
  onPaymentClick,
  price,
  restaurantPlatformId,
}: CartPageFooterProps) => {
  const { formatPrice } = useFormatPrice();
  const [couponError, setCouponError] = useState<CouponError>();
  const { cart, setCart } = useCart();

  const {
    mutate: submitCoupon,
    data: validatedOrderWithCoupon,
    isPending: isCouponPending,
    reset,
  } = useValidateOrderMutation({
    onSuccess: ({ couponInfo }) => {
      if (couponInfo?.couponAccepted) {
        setCart({ ...cart, couponCode: couponInfo.couponCode });
        if (couponError) setCouponError(undefined);
      } else setCouponError(couponInfo?.error as CouponError);
    },
    onError: () => setCouponError({ errorType: CouponInfoErrorTypeEnum.BACKEND_ERROR }),
  });

  // const isCouponFormAvailable = PROMO_AVAILABLE_COUNTRIES.has(languageCode);
  const isCouponFormAvailable = false;

  const validateCoupon = ({ couponCode }: { couponCode: string }) => {
    if (!isCouponFormAvailable) return;

    submitCoupon({
      cart: {
        ...formatCartForValidation({
          ...cart,
        }),
        couponCode,
      },
    });
  };

  const removeCoupon = () => {
    reset();
    setCouponError(undefined);
    setCart({ ...cart, couponCode: undefined });
  };

  const originalPrice = formatPrice(price, currencyCode, { maximumFractionDigits: 2 });

  return (
    <Grid templateColumns="repeat(2, auto)" justifyContent="space-between" gap={8}>
      <Button
        as={Link}
        to={`/onSite/menu/${restaurantPlatformId}`}
        backgroundColor="white"
        colorScheme="gray"
        variant="outline"
        textTransform="uppercase"
        paddingY={6}
        marginX={8}
        height="fit-content"
        borderRadius="lg"
        gridColumnStart={1}
        gridColumnEnd={-1}
      >
        <Text fontSize="2xl" fontWeight={700}>
          + <Trans>Add more items</Trans>
        </Text>
      </Button>
      {isCouponFormAvailable && (
        <CouponForm
          isDisabled={isLoading}
          isLoading={isCouponPending}
          currencyCode={currencyCode}
          reductionAmount={validatedOrderWithCoupon?.couponInfo?.couponReduction}
          onRemove={removeCoupon}
          onSubmit={validateCoupon}
          couponError={couponError}
          isKiosk
          sx={{ gridColumnStart: 1, gridColumnEnd: -1, px: 8 }}
        />
      )}
      <Flex
        justifyContent="space-between"
        px={8}
        sx={{ gridColumnStart: -1, gridColumnEnd: 1, px: 8, fontSize: '4xl' }}
      >
        <Text fontWeight={700} textTransform="uppercase">
          <Trans>Total</Trans>
        </Text>
        {isCouponPending ? (
          <Spinner size="lg" />
        ) : validatedOrderWithCoupon?.couponInfo?.couponAccepted ? (
          <Flex gap={2} placeSelf="end">
            <Text color="gray.400" textDecoration="line-through">
              {originalPrice}
            </Text>
            <Text>{formatPrice(validatedOrderWithCoupon?.couponInfo.reductedPrice, currencyCode)}</Text>
          </Flex>
        ) : (
          <Text fontWeight={700} paddingRight={8} textAlign="end">
            {formatPrice(price, currencyCode)}
          </Text>
        )}
      </Flex>
      <Grid
        gridTemplateColumns="repeat(2, minmax(0, 1fr))"
        backgroundColor="white"
        borderTop="1px solid #D9D9D9"
        boxShadow="0px -4px 16px 0px #0000001A"
        paddingX={6}
        paddingY={4}
        alignItems="center"
        justifyContent="space-between"
        columnGap={8}
        rowGap={2}
        gridColumn="1 / -1"
      >
        <IconButton
          aria-label={t`Go back`}
          as={Link}
          colorScheme="gray"
          to={`/onSite/menu/${restaurantPlatformId}`}
          variant="outline"
          icon={<Image src={ArrowLeft} width={30} />}
          sx={{
            borderColor: 'gray.300',
            height: '82px',
            maxWidth: '82px',
            width: '100%',
          }}
        />
        <Button
          isLoading={isLoading || isCouponPending}
          loadingText={
            <Text fontSize="3xl">
              <Trans>Pay</Trans>
            </Text>
          }
          isDisabled={isError}
          colorScheme="green"
          sx={{
            height: '82px',
            maxWidth: '400px',
            width: '100%',
          }}
          justifySelf="end"
          onClick={onPaymentClick}
        >
          <Text fontSize="3xl" fontWeight={700} textTransform="uppercase">
            <Trans>Pay</Trans>
          </Text>
        </Button>
        {hasOutOfStocks && (
          <Text fontSize="md" fontWeight="700" gridColumn="1 / -1" color="gray.600" justifySelf="end">
            <Trans>To continue, remove unavailable items</Trans>
          </Text>
        )}
      </Grid>
    </Grid>
  );
};
