import { Center, Flex, Heading, Image, Stack, Text } from '@chakra-ui/react';
import { t, Trans } from '@lingui/macro';
import QRCode from 'react-qr-code';
import { Navigate, useParams } from 'react-router-dom';

import { useOrderQuery } from '@/api/gateway-click-collect/orders';
import cycleAnimation from '@/assets/anim/lottie_cycle.json';
import QrCodeArrow from '@/assets/img/qr_code_arrow.svg';
import footerLogo from '@/assets/img/taster_footer.png';
import { LottieAnimation } from '@/components/LottieAnimation';
import { NextCustomerProgressBar } from '@/components/NextCustomerProgressBar';
import { useTerminalContext } from '@/contexts';
import { SessionEndReasonEnum, useOnSiteSession } from '@/contexts/onSiteSession';
import { OrderReceiptForm } from '@/routes/PayOnSite/OrderConfirmationPage/OrderReceiptForm';

import { PairDeviceLayout } from '../PairDeviceLayout';

export const OrderConfirmationPage = () => {
  const { orderUuid } = useParams() as { orderUuid: string };
  const { endOnSiteSession } = useOnSiteSession();
  const { userLocation } = useTerminalContext();

  const { data: order, isPending: isOrderPending } = useOrderQuery({
    requestParams: {
      orderUuid,
    },
  });

  if (order) {
    const { uuid, number, couponInfo } = order;
    const canAskForReceipt = !couponInfo?.couponAccepted;
    const orderQrCodeUrl = window.location.port
      ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}/order/${orderUuid}`
      : `${window.location.protocol}//${window.location.hostname}/order/${orderUuid}`;

    return (
      <PairDeviceLayout headerTitle={t`Order confirmed`}>
        <Stack px={2} gap={6} alignItems="center">
          <Heading fontSize={64} fontWeight={700}>
            # {number}
          </Heading>
          <Heading fontSize={32} fontWeight={700} textAlign="center">
            <Trans>Thanks for your order! Track it here</Trans>
          </Heading>
          <Flex justifyContent="center" py={10} position="relative">
            <QRCode value={orderQrCodeUrl} size={200} />
            <Image
              src={QrCodeArrow}
              sx={{
                display: { base: 'none', sm: 'block' },
                position: 'absolute',
                transform: 'translateX(120%) translateY(-25%)',
              }}
            />
          </Flex>
          {canAskForReceipt ? (
            <OrderReceiptForm orderUuid={uuid} />
          ) : (
            <Text textAlign="center" fontSize="4xl">
              <Trans>To obtain a receipt, please contact support@taster.com</Trans>
            </Text>
          )}
          <NextCustomerProgressBar
            locationUuid={userLocation.locationUuid}
            onNextCustomer={() => {
              endOnSiteSession({ sessionEndReason: SessionEndReasonEnum.PAYED });
            }}
            sx={{ marginTop: 10 }}
          />
          <Center marginTop={10}>
            <Image src={footerLogo} alt="Taster" width={420} />
          </Center>
        </Stack>
      </PairDeviceLayout>
    );
  }

  if (isOrderPending) {
    return (
      <PairDeviceLayout headerTitle={t`Order confirmed`}>
        <Stack alignItems="center">
          <LottieAnimation animation={cycleAnimation} sx={{ width: '250px' }} />
          <Heading as="span" fontWeight={700} fontSize={32}>
            <Trans>Recovering your order…</Trans>
          </Heading>
        </Stack>
      </PairDeviceLayout>
    );
  }

  return <Navigate to={`/onSite/notFound`} />;
};
