import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Button, Skeleton, Text, VStack } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { FaHome } from 'react-icons/fa';

import { useRestaurantsQuery } from '@/api/gateway-click-collect';
import type { Coords } from '@/api/gmap';
import { RequestLocationButton } from '@/components/RequestLocationButton';
import { RestaurantCard } from '@/routes/BrandPage/RestaurantCard';

export interface RestaurantListProps {
  coords: Coords;
  onErrorButtonClick: () => void;
  conceptUuid: string;
  backgroundColor?: string;
}

export const RestaurantList = ({ coords, backgroundColor, conceptUuid, onErrorButtonClick }: RestaurantListProps) => {
  const { latitude, longitude } = coords;
  const {
    data: restaurantList,
    isPending: isRestaurantListPending,
    isError: isRestaurantListError,
  } = useRestaurantsQuery({
    requestParams: {
      conceptUuid,
      lat: latitude,
      _long: longitude,
      withOpeningInformation: false,
    },
    options: {
      select: (nearbyRestaurants) => {
        return nearbyRestaurants.sort((restaurantA, restaurantB) =>
          restaurantA.distance! > restaurantB.distance! ? 1 : -1
        );
      },
    },
  });

  if (restaurantList?.length) {
    return (
      <VStack gap={4}>
        {restaurantList.map((restaurant) => {
          return (
            <RestaurantCard
              key={restaurant.restaurantPlatformId}
              coords={coords}
              restaurant={restaurant}
              backgroundColor={backgroundColor}
            />
          );
        })}
      </VStack>
    );
  }

  if (isRestaurantListPending) {
    return (
      <VStack>
        {Array.from({ length: 10 }).map((_, index) => (
          <Skeleton key={index} height="172px" width="100%" bg="#F8F7F54D" borderRadius={20} />
        ))}
      </VStack>
    );
  }

  if (isRestaurantListError) {
    return (
      <VStack height="100%" justify="center" align="center" gap={6} p={5}>
        <Text fontSize="xl" fontWeight={600} textAlign="center">
          <Trans>Sorry, something went wrong. Return to homepage and try again. </Trans>
        </Text>
        <Button
          leftIcon={<FaHome />}
          onClick={onErrorButtonClick}
          backgroundColor="black"
          color="white"
          _hover={{ bg: '#18181b' }}
          borderRadius={20}
        >
          <Trans>Home</Trans>
        </Button>
      </VStack>
    );
  }

  return (
    <VStack height="100%" justify="center" align="center" gap={6} p={5}>
      <Text fontSize="xl" fontWeight={600} textAlign="center">
        <Trans>Snap! We don’t have Taster brands here yet.</Trans>
      </Text>
      <Text fontSize="xl" fontWeight={600} textAlign="center">
        <Trans>
          Why not submit your location and we will look into getting one of our brands in your location soon!
        </Trans>
      </Text>
      <RequestLocationButton
        alignSelf="center"
        backgroundColor="black"
        color="white"
        _hover={{ bg: '#18181b' }}
        borderRadius={20}
        rightIcon={<ExternalLinkIcon />}
      />
    </VStack>
  );
};
