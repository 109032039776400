import { CheckCircleIcon, CloseIcon } from '@chakra-ui/icons';
import { Button, HStack, Icon, Stack, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { AiOutlineWarning } from 'react-icons/ai';
import { MdCloudOff } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { RegisteredTerminalStatusEnum, useCancelActionMutation } from '@/api/gateway-click-collect/terminals';
import Card from '@/components/Card';

import { RefreshTerminalStatusContent } from './RefreshTerminalStatusContent';
import { TerminalStatusErrorCard } from './TerminalStatusErrorCard';

export interface TerminalStatusCardProps {
  lastUpdateAt: number;
  isRefreshing?: boolean;
  onRefreshClick: () => void;
  registeredTerminalStatus: RegisteredTerminalStatusEnum;
  locationUuid: string;
  terminalUuid: string;
}

export const TerminalStatusCard = ({
  lastUpdateAt,
  isRefreshing = false,
  onRefreshClick,
  registeredTerminalStatus,
  locationUuid,
  terminalUuid,
}: TerminalStatusCardProps) => {
  const { mutate: deleteOngoingPayment, isPending } = useCancelActionMutation({
    onSuccess: onRefreshClick,
  });

  if (registeredTerminalStatus === RegisteredTerminalStatusEnum.Unavailable)
    return (
      <TerminalStatusErrorCard
        isRefreshing={isRefreshing}
        errorStatus={410}
        lastUpdateAt={lastUpdateAt}
        onRefreshClick={onRefreshClick}
      />
    );

  if (registeredTerminalStatus === RegisteredTerminalStatusEnum.Ready)
    return (
      <Card
        sx={{
          backgroundColor: 'green.success.50',
          border: '1px solid',
          borderColor: 'green.success.700',
          p: 4,
        }}
      >
        <HStack gap={2} justifyContent="space-between" alignItems="start">
          <Stack>
            <Text fontSize={20} fontWeight={600}>
              <Trans>Status</Trans>
            </Text>
            <HStack gap={2} alignItems="center">
              <CheckCircleIcon color="green.success.icon" width={5} height={5} />
              <Text color="green.success.600" fontSize={20} fontWeight={700}>
                <Trans>Ready</Trans>
              </Text>
            </HStack>
          </Stack>
          <RefreshTerminalStatusContent
            onRefreshClick={onRefreshClick}
            isRefreshing={isRefreshing}
            lastUpdatedAt={lastUpdateAt}
          />
        </HStack>
      </Card>
    );

  if (registeredTerminalStatus === RegisteredTerminalStatusEnum.Busy)
    return (
      <Card
        sx={{
          backgroundColor: 'red.warning.100',
          border: '1px solid',
          borderColor: 'red.warning.700',
          p: 4,
        }}
      >
        <Stack gap={2}>
          <HStack gap={2} justifyContent="space-between">
            <Text fontSize={20} fontWeight={600}>
              <Trans>Status</Trans>
            </Text>
            <RefreshTerminalStatusContent
              onRefreshClick={onRefreshClick}
              isRefreshing={isRefreshing}
              lastUpdatedAt={lastUpdateAt}
            />
          </HStack>
          <HStack flexWrap="wrap" justifyContent="space-between" alignItems="start">
            <HStack gap={2} alignItems="start">
              <Icon as={AiOutlineWarning} width={6} height={6} color="red.warning.700" />
              <Stack gap={1}>
                <Text color="red.warning.800" fontWeight={600} fontSize={20}>
                  <Trans>Payment in progress</Trans>
                </Text>
                <Text color="red.warning.800">
                  <Trans>To make the card machine available again, cancel the payment request.</Trans>
                </Text>
              </Stack>
            </HStack>
            <Button
              onClick={() => deleteOngoingPayment({ locationUuid, terminalUuid })}
              leftIcon={<CloseIcon />}
              isLoading={isPending}
              loadingText={<Trans>Canceling payment</Trans>}
              colorScheme="red"
              maxW={190}
              borderRadius={8}
            >
              <Trans>Cancel payment</Trans>
            </Button>
          </HStack>
        </Stack>
      </Card>
    );

  if (registeredTerminalStatus === RegisteredTerminalStatusEnum.Offline)
    return (
      <Card
        sx={{
          backgroundColor: 'red.warning.100',
          border: '1px solid',
          borderColor: 'red.warning.700',
          p: 4,
        }}
      >
        <Stack gap={2}>
          <HStack gap={2} justifyContent="space-between" flexWrap="wrap">
            <Text fontSize={20} fontWeight={600}>
              <Trans>Status</Trans>
            </Text>
            <RefreshTerminalStatusContent
              onRefreshClick={onRefreshClick}
              isRefreshing={isRefreshing}
              lastUpdatedAt={lastUpdateAt}
            />
          </HStack>
          <HStack alignItems="start" gap={3}>
            <Icon as={MdCloudOff} width={5} height={5} mt={1} color="red.warning.700" />
            <Stack gap={1}>
              <Text color="red.warning.800" fontWeight={600} fontSize={20}>
                <Trans>Offline</Trans>
              </Text>
              <Text color="red.warning.800">
                <Trans>
                  Make sure the card machine is switched on and connected to the internet. If it still doesn’t work, try{' '}
                  <Link to={'/onSite/internalLocations'}>
                    <Text fontWeight={700} textDecoration="underline" as="span">
                      pairing the card machine
                    </Text>{' '}
                  </Link>
                  again.
                </Trans>
              </Text>
            </Stack>
          </HStack>
        </Stack>
      </Card>
    );

  return <></>;
};
