import '@fontsource-variable/dm-sans';
import './index.css';

import { ChakraProvider } from '@chakra-ui/react';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import * as Sentry from '@sentry/react';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createRoot } from 'react-dom/client';
import { AuthProvider } from 'react-oidc-context';

import ApiError from '@/api/ApiError';
import { initSentry } from '@/sentry';

import { TasterApisProvider } from './api/TasterApiContext';
import AppRouter from './AppRouter';
import { authProviderOptions } from './auth/authProviderOptions';
import { CartProvider } from './contexts/cart';
import { OnSiteSessionProvider } from './contexts/onSiteSession';
import { PendingOrderProvider } from './contexts/PendingOrderProvider';
import { activateI18n } from './i18n';
import theme from './theming/Theme';

if (import.meta.env.PROD) {
  initSentry();
}

activateI18n();

const isApiClientError = (apiError: unknown): apiError is { error: Error } => {
  return typeof apiError === 'object' && apiError !== null && 'error' in apiError;
};

const reportErrorToSentry = (error: unknown) => {
  let _error = error;

  if (isApiClientError(_error)) {
    _error = _error.error;
  }

  if (_error instanceof Error) {
    Sentry.captureException(_error);
  } else {
    console.error(_error);
  }
};

export const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      onError: reportErrorToSentry,
    },
    queries: {
      refetchOnWindowFocus: false,
      retry(failureCount, error) {
        if (failureCount >= 3) return false;

        if (error instanceof ApiError) {
          if (error.status === 401) return true;
          if (error.status < 500) return false;
        }

        return true;
      },
    },
  },
  queryCache: new QueryCache({
    onError: reportErrorToSentry,
  }),
});

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <AuthProvider {...authProviderOptions}>
    <I18nProvider i18n={i18n}>
      <ChakraProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <TasterApisProvider>
            <PendingOrderProvider>
              <CartProvider>
                <OnSiteSessionProvider>
                  <AppRouter />
                </OnSiteSessionProvider>
              </CartProvider>
            </PendingOrderProvider>
          </TasterApisProvider>
        </QueryClientProvider>
      </ChakraProvider>
    </I18nProvider>
  </AuthProvider>
);
