import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Trans } from '@lingui/macro';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeError } from '@stripe/stripe-js';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { ReactNode, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { ReadiedOrderSummary, useSetOrderEmailMutation } from '@/api/gateway-click-collect/orders';
import dataLayer from '@/helpers/dataLayer.helpers';
import useFormatPrice from '@/hooks/useFormatPrice';

import { TermsAgreementCheckboxLabel } from './TermsAgreementCheckboxLabel';

interface CheckoutFormProps {
  conceptLabel?: string;
  kitchenLabel: string;
  order: ReadiedOrderSummary;
  paymentMethodSelector?: ReactNode;
}

const canPayOrder = (pickupTime?: Date | null) => {
  if (!pickupTime) {
    return false;
  }

  const maxTime = 9;

  return dayjs(pickupTime).diff(dayjs(), 'minute') <= maxTime;
};

export const CheckoutForm = ({ kitchenLabel, conceptLabel, paymentMethodSelector, order }: CheckoutFormProps) => {
  const { formatPrice } = useFormatPrice();
  const elements = useElements();
  const navigate = useNavigate();
  const stripe = useStripe();

  const { currency, pickupTime, totalPrice, uuid, couponInfo, number } = order;
  const isPreOrder = !!pickupTime;
  const canAskForReceipt = !couponInfo;

  const [isTooLateForPayment, setIsTooLateForPayment] = useState(() => canPayOrder(pickupTime));

  useEffect(() => {
    if (!pickupTime) return;

    const paymentTimeInterval = setInterval(() => {
      setIsTooLateForPayment(canPayOrder(pickupTime));
    }, 2000);

    return () => clearInterval(paymentTimeInterval);
  }, [pickupTime]);

  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm({
    defaultValues: {
      areTermsAccepted: false,
      customerEmail: '',
      isOptedIn: false,
      name: '',
    },
    resolver: yupResolver(
      yup.object({
        areTermsAccepted: yup.boolean().isTrue(),
        customerEmail: yup.string().email(),
        isOptedIn: yup.boolean(),
        name: yup.string().required(),
      })
    ),
  });

  const {
    data: paymentResponse,
    isPending: isPendingPayment,
    mutate: confirmPayment,
  } = useMutation<never | { error: StripeError }, Error, { customerEmail?: string; name: string }>({
    mutationFn: ({ customerEmail, name }) => {
      if (stripe && elements) {
        return stripe.confirmPayment({
          elements,
          confirmParams: {
            payment_method_data: { billing_details: { email: customerEmail, name } },
            return_url: window.location.port
              ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}/confirmPayment/${order.uuid}`
              : `${window.location.protocol}//${window.location.hostname}/confirmPayment/${order.uuid}`,
          },
        });
      }

      return Promise.reject('Payment must be processed within a stripe Elements provider');
    },
  });

  const { mutate: sendReceipt, isPending: isPendingReceipt, isError: hasReceiptError } = useSetOrderEmailMutation();

  const submitCheckoutForm = handleSubmit(({ customerEmail, isOptedIn, name }) => {
    dataLayer.clickPay({
      brandLabel: conceptLabel,
      currencyCode: currency,
      kitchenLabel,
      orderChannel: 'online',
      orderNumber: number,
      orderType: pickupTime ? 'preorder' : 'asap_order',
      paymentValue: totalPrice,
    });

    if (isPreOrder && canAskForReceipt) {
      sendReceipt(
        {
          orderUuid: uuid,
          emailUpdateRequest: {
            customerEmail,
            marketingConsent: isOptedIn,
          },
        },
        {
          onSuccess: async () => confirmPayment({ customerEmail, name }),
        }
      );
    } else {
      confirmPayment({ customerEmail, name });
    }
  });

  const isPaymentButtonDisabled = !stripe || !elements || !isValid || isTooLateForPayment;
  const isPending = isPendingPayment || isPendingReceipt;
  const showError = hasReceiptError || paymentResponse?.error?.message;

  return (
    <form onSubmit={submitCheckoutForm}>
      <Stack gap={1} my={2}>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <FormControl mb={3} isRequired>
              <FormLabel>
                <Trans>Name</Trans>
              </FormLabel>
              <Input {...field} />
              <FormHelperText>
                <Trans>To pickup your order </Trans>
              </FormHelperText>
            </FormControl>
          )}
        />
        {isPreOrder && canAskForReceipt && (
          <Controller
            name="customerEmail"
            control={control}
            render={({ field }) => (
              <FormControl mb={3} isRequired>
                <FormLabel>
                  <Trans>Email</Trans>
                </FormLabel>
                <Input type="email" {...field} />
                <FormHelperText>
                  <Trans>To get a receipt</Trans>
                </FormHelperText>
              </FormControl>
            )}
          />
        )}
        <PaymentElement />
        {showError && (
          <Alert status="error" mt={4}>
            <AlertIcon />
            <div>
              {hasReceiptError && (
                <AlertDescription>
                  <Trans>
                    Preordering is unavailable right now. Please try again and place your order for pickup ASAP.
                  </Trans>
                </AlertDescription>
              )}
              {!!paymentResponse?.error.message && <AlertDescription>{paymentResponse.error.message}</AlertDescription>}
            </div>
          </Alert>
        )}
        <Controller
          name="areTermsAccepted"
          control={control}
          render={({ field: { value, ...fieldRest } }) => (
            <FormControl isRequired>
              <Checkbox {...fieldRest} spacing={6} isChecked={value}>
                <TermsAgreementCheckboxLabel />
              </Checkbox>
            </FormControl>
          )}
        />
        {isPreOrder && (
          <Controller
            name="isOptedIn"
            control={control}
            render={({ field: { value, ...fieldRest } }) => (
              <Checkbox isChecked={value} spacing={6} {...fieldRest}>
                <Trans>Yes, I would like to be the first to know about exclusive offers and discounts.</Trans>
              </Checkbox>
            )}
          />
        )}
      </Stack>
      <Button isDisabled={isPaymentButtonDisabled} isLoading={isPending} mt={4} size="lg" type="submit" w="100%">
        <Trans>Pay {formatPrice(totalPrice, currency)}</Trans>
      </Button>
      {paymentMethodSelector}
      {isTooLateForPayment && (
        <Stack>
          <Text color="gray.disabled" pt="3">
            <Trans>The pickup time you selected has passed.</Trans>
          </Text>
          <Button variant="link" onClick={() => navigate(-1)}>
            <Trans>Choose a new time instead</Trans>
          </Button>
        </Stack>
      )}
    </form>
  );
};
