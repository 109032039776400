import { Button, Stack, useDisclosure } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { useState } from 'react';

import { useSetOrderEmailMutation } from '@/api/gateway-click-collect/orders';
import { SubmitReceiptEventHandler } from '@/components/Order/Receipt/ReceiptForm';
import { ReceiptModal } from '@/components/Order/Receipt/ReceiptModal';
import { ReceiptStatus } from '@/components/Order/Receipt/ReceiptStatus';

export interface OrderReceiptButtonProps {
  orderUuid: string;
}

export const OrderReceiptButton = ({ orderUuid }: OrderReceiptButtonProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [customerEmail, setCustomerEmail] = useState('');

  const { isPending, mutate: sendReceipt, isError, isSuccess } = useSetOrderEmailMutation();

  const handleSubmit: SubmitReceiptEventHandler = (email, isOptedIn) => {
    sendReceipt(
      {
        orderUuid,
        emailUpdateRequest: {
          customerEmail: email,
          marketingConsent: isOptedIn,
        },
      },
      {
        onSuccess: () => {
          setCustomerEmail(email);
        },
        onSettled: () => {
          onClose();
        },
      }
    );
  };

  return (
    <Stack gap={4}>
      <Button size="lg" colorScheme="gray" backgroundColor="gray.300" onClick={onOpen}>
        <Trans>Get receipt</Trans>
      </Button>
      <ReceiptStatus email={customerEmail} isSuccess={isSuccess} isError={isError} />
      <ReceiptModal
        isOpen={isOpen}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={isPending}
        isReceiptSent={isSuccess}
      />
    </Stack>
  );
};
