import { Container, Text, VStack } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { useMemo } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';

import { ListRestaurantsRequest, useConceptsConfigurationQuery } from '@/api/gateway-click-collect';
import { Geolocation } from '@/components/GeolocationAutocomplete';
import PageLoader from '@/components/PageLoader';
import { UserLocationButton } from '@/components/UserLocationButton';
import { logLocationClick } from '@/helpers/dataLayer.helpers';
import usePageViewTracker from '@/hooks/usePageViewTracker';
import { useRestaurantWithOpeningInformationsQuery } from '@/hooks/useRestaurantWithOpeningInformationsQuery';
import { useSessionStorage } from '@/hooks/useSessionStorage';

import { LocationFinderBody } from './LocationFinderBody';
import { LocationFinderHeader } from './LocationFinderHeader';
import { buildKitchenInfos } from './LocationFinderPage.helpers';

export const LocationFinderPage = () => {
  usePageViewTracker();
  const [userLocation, setUserLocation] = useSessionStorage<Geolocation | null>('location', null);

  const restaurantListRequestParams = useMemo<ListRestaurantsRequest>(() => {
    if (!userLocation?.coords?.latitude || !userLocation.coords?.longitude) return {};

    const {
      coords: { latitude, longitude },
    } = userLocation;

    return {
      lat: latitude,
      _long: longitude,
    };
  }, [userLocation]);

  const {
    data: restaurantList = [],
    isPending: areRestaurantsPending,
    isError: areRestaurantsOnError,
  } = useRestaurantWithOpeningInformationsQuery({
    requestParams: restaurantListRequestParams,
    options: {
      enabled: !!userLocation,
    },
  });

  const restaurantListConceptUuids = [...new Set(restaurantList.map(({ conceptUuid }) => conceptUuid))];

  const {
    data: concepts,
    isPending: areConceptsPending,
    isError: areConceptsOnError,
  } = useConceptsConfigurationQuery({
    options: {
      gcTime: Infinity,
      staleTime: Infinity,
      select: ({ concepts }) => concepts,
    },
  });

  const [conceptQuery, setConceptQuery] = useQueryParam('conceptUuid', StringParam);

  const handleConceptChange = (conceptUuid: string) => {
    setConceptQuery(conceptUuid);
  };

  const selectedConceptUuid = conceptQuery?.length ? conceptQuery : '';

  const displayedConceptsUuids = selectedConceptUuid?.length ? [selectedConceptUuid] : restaurantListConceptUuids;

  const availableConcepts = concepts?.filter((concept) => restaurantListConceptUuids?.includes(concept.uuid)) ?? [];

  const kitchenInfos = buildKitchenInfos({
    restaurantList,
    concepts,
  });

  const filteredKitchenInfos = kitchenInfos
    .map((restaurantList) => restaurantList.filter(({ concept }) => displayedConceptsUuids.includes(concept.uuid)))
    .filter((list) => !!list.length);

  const handleUserPosition = ({ coords }: GeolocationPosition) => {
    const { latitude, longitude } = coords;
    setUserLocation({ coords: { latitude, longitude } });
    logLocationClick();
  };

  return (
    <Container px={0} height="100%" display="flex" flexDirection="column">
      <LocationFinderHeader
        defaultAddress={userLocation?.address}
        concepts={availableConcepts}
        onChange={handleConceptChange}
        onSelect={setUserLocation}
        value={selectedConceptUuid}
      />
      {areConceptsPending && userLocation ? (
        <PageLoader />
      ) : (
        <>
          {userLocation?.coords ? (
            <LocationFinderBody
              coords={userLocation.coords}
              isError={areRestaurantsOnError || areConceptsOnError}
              isLoading={areRestaurantsPending}
              kitchenRestaurantLists={filteredKitchenInfos}
            />
          ) : (
            <VStack flex={1} gap={6} justify="center" align="center">
              <UserLocationButton onGeolocation={handleUserPosition} />
              <Text fontSize="xs" fontWeight={300} color="gray.600" textAlign="center">
                <Trans>Enter your location to find stores near you</Trans>
              </Text>
            </VStack>
          )}
        </>
      )}
    </Container>
  );
};
