import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';
import { Alert, AlertDescription, chakra, Collapse, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';

export interface ReceiptStatusProps {
  email?: string;
  isError: boolean;
  isSuccess: boolean;
}

const StyledAlert = chakra(Alert, {
  baseStyle: {
    borderRadius: 12,
    gap: 3,
    padding: 2,
    border: '1px solid',
    display: 'flex',
    justifyContent: 'center',
  },
});

export const ReceiptStatus = ({ email = '', isSuccess, isError }: ReceiptStatusProps) => {
  return (
    <>
      <Collapse animateOpacity in={isSuccess}>
        <StyledAlert status="success" background="green.success.25" borderColor="green.success.300">
          <CheckCircleIcon color="green.success.300" />
          <Text fontSize="sm" fontWeight={600} color="green.success.700">
            <Trans>Your receipt have been sent to {email}</Trans>
          </Text>
        </StyledAlert>
      </Collapse>
      <Collapse animateOpacity in={isError}>
        <StyledAlert status="error" background="red.error.25" borderColor="red.error.300">
          <WarningIcon color="red.error.600" />
          <AlertDescription fontSize="sm" fontWeight={600} color="red.error.700">
            <Trans>Unable to send receipt.</Trans>
          </AlertDescription>
        </StyledAlert>
      </Collapse>
    </>
  );
};
