import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import ApiError from '@/api/ApiError';
import { ListRestaurantsRequest, Restaurant } from '@/api/gateway-click-collect/restaurants/types';
import { useTasterApis } from '@/api/TasterApiContext';

export const useRestaurantWithOpeningInformationsQueryOptions = {
  queryKey: (requestParams: Omit<ListRestaurantsRequest, 'withOpeningInformation'>) => [
    'listWithOpeningInformations',
    requestParams,
  ],
};

export interface UseRestaurantsQueryProps<TData> {
  options?: Omit<
    UseQueryOptions<
      Restaurant[],
      ApiError,
      TData,
      ReturnType<typeof useRestaurantWithOpeningInformationsQueryOptions.queryKey>
    >,
    'queryFn' | 'queryKey'
  >;
  requestParams: Omit<ListRestaurantsRequest, 'withOpeningInformation'>;
}

export const useRestaurantWithOpeningInformationsQuery = <TData = Restaurant[]>({
  options,
  requestParams,
}: UseRestaurantsQueryProps<TData>) => {
  const {
    gatewayClickCollect: { restaurantsApi },
  } = useTasterApis();

  return useQuery({
    ...options,
    queryFn: async () => {
      const restaurants = await restaurantsApi.listRestaurants(requestParams);

      return Promise.all(
        restaurants.map(async ({ restaurantPlatformId, ...restaurant }) => {
          const [{ status }, openingHours] = await Promise.all([
            restaurantsApi.getRestaurantStatus({ restaurantPlatformId }),
            restaurantsApi.getRestaurantOpeningHours({ restaurantPlatformId }),
          ]);

          return {
            ...restaurant,
            restaurantPlatformId,
            openingHours,
            status,
          };
        })
      );
    },
    queryKey: useRestaurantWithOpeningInformationsQueryOptions.queryKey(requestParams),
  });
};
