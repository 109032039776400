import { Alert, AlertIcon, Box, Button, Flex, Heading, Image, Stack, useDisclosure } from '@chakra-ui/react';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe, StripeElementsOptionsClientSecret } from '@stripe/stripe-js';
import { useQuery } from '@tanstack/react-query';
import { Link, useParams } from 'react-router-dom';

import {
  GetPaymentProviderConfigurationCountryCodeEnum,
  GetPaymentProviderConfigurationCurrencyCodeEnum,
  usePaymentProviderConfigurationQuery,
} from '@/api/gateway-click-collect/configurations';
import { ReadiedOrderSummary, useOrderQuery } from '@/api/gateway-click-collect/orders';
import { Restaurant, useRestaurantQuery } from '@/api/gateway-click-collect/restaurants';
import checkoutImgSrc from '@/assets/img/checkout.png';
import Card from '@/components/Card';
import { CheckoutForm } from '@/components/CheckoutForm';
import { OrderInfoCard } from '@/components/Order/OrderInfoCard';
import PageLoader from '@/components/PageLoader';
import PaymentMethodModal from '@/components/PaymentMethodModal';
import { useCart } from '@/contexts/cart';
import dataLayer from '@/helpers/dataLayer.helpers';
import usePageViewTracker from '@/hooks/usePageViewTracker';
import { SupportedLocales } from '@/i18n';
import { AppLayout } from '@/layouts/AppLayout';

const stripeOptions: StripeElementsOptionsClientSecret = {
  appearance: {
    theme: 'stripe',
    variables: { fontFamily: 'DM Sans Variable, sans-serif' },
    rules: {
      '.Label': {
        fontFamily: 'DM Sans Variable, sans-serif',
        fontSize: '16px',
        lineHeight: '24px',
        color: 'rgb(26, 32, 44)',
      },
      '.Input:hover': {
        borderColor: '#000',
      },
      '.Input:focus': {
        borderColor: '#000000',
        boxShadow: `0 0 0 1px #000000`,
      },
    },
  },
};

interface CheckoutProps {
  conceptLabel?: string;
  order: ReadiedOrderSummary;
  restaurant: Restaurant;
  stripeObject: Stripe;
}

const Checkout = ({ conceptLabel, order, restaurant, stripeObject }: CheckoutProps) => {
  const {
    isOpen: isPaymentMethodModalOpen,
    onOpen: onPaymentMethodModalOpen,
    onClose: onPaymentMethodModalClose,
  } = useDisclosure();

  const { restaurantPlatformId, address, restaurantLabel, kitchenInstructions, kitchenLabel } = restaurant;
  const { pickupTime, piSecret } = order;

  return (
    <AppLayout title={t`Payment`} hasArrowBack url={`/menu/${restaurantPlatformId}`}>
      <Box p={6} pb={0}>
        <OrderInfoCard
          kitchenAccessInstructions={kitchenInstructions}
          kitchenAddress={address}
          kitchenName={restaurantLabel}
          pickupTime={pickupTime}
        />
      </Box>
      <Box p={6}>
        <Card>
          <Image src={checkoutImgSrc} margin="auto" width="144px" />
          <Heading as="h5" size="sm" textAlign="center">
            <Trans>Payment info</Trans>
          </Heading>
          <Box mt={6}>
            <Elements options={{ ...stripeOptions, clientSecret: piSecret ?? undefined }} stripe={stripeObject}>
              <CheckoutForm
                conceptLabel={conceptLabel}
                kitchenLabel={kitchenLabel}
                order={order}
                paymentMethodSelector={
                  <>
                    <PaymentMethodModal
                      isOpen={isPaymentMethodModalOpen}
                      onClose={onPaymentMethodModalClose}
                      onSelected={(paymentMethod, other) =>
                        dataLayer.logPaymentMethodSelected({
                          conceptLabel,
                          kitchenLabel,
                          other,
                          paymentMethod,
                        })
                      }
                    />
                    <Flex justifyContent="center" mt={4}>
                      <Button
                        variant="link"
                        margin="auto"
                        onClick={() => {
                          dataLayer.logPaymentMethodSelectorOpened({
                            conceptLabel,
                            kitchenLabel,
                          });
                          onPaymentMethodModalOpen();
                        }}
                      >
                        <Trans>Use another payment method</Trans>
                      </Button>
                    </Flex>
                  </>
                }
              />
            </Elements>
          </Box>
        </Card>
      </Box>
    </AppLayout>
  );
};

export const CheckoutPage = () => {
  const { orderUuid } = useParams() as { orderUuid: string };
  const { cart } = useCart();
  const { restaurantPlatformId, conceptLabel, kitchenLabel } = cart;

  usePageViewTracker(kitchenLabel, conceptLabel);

  const {
    data: order,
    isError: hasOrderError,
    isPending: isOrderPending,
  } = useOrderQuery({ requestParams: { orderUuid } });

  const {
    data: restaurant,
    isError: hasRestaurantError,
    isPending: isRestaurantPending,
  } = useRestaurantQuery({
    requestParams: { restaurantPlatformId },
  });

  const {
    data: paymentConfiguration,
    isError: hasPaymentConfigurationError,
    isPending: isPaymentConfigurationPending,
  } = usePaymentProviderConfigurationQuery({
    requestParams: {
      countryCode: restaurant?.languageCode as GetPaymentProviderConfigurationCountryCodeEnum,
      currencyCode: restaurant?.currencyCode as GetPaymentProviderConfigurationCurrencyCodeEnum,
    },
    options: {
      enabled: !!restaurant,
    },
  });

  const {
    data: stripeObject,
    isError: hasStripeError,
    isPending: isStripePending,
  } = useQuery({
    queryKey: ['loadStripe', paymentConfiguration?.publishableKey],
    queryFn: () => {
      if (paymentConfiguration) {
        return loadStripe(paymentConfiguration.publishableKey, {
          locale: i18n.locale as SupportedLocales,
        });
      }
    },
    enabled: !!paymentConfiguration,
    gcTime: 0,
  });

  const isPending = isPaymentConfigurationPending || isRestaurantPending || isOrderPending || isStripePending;
  const isError = hasOrderError || hasPaymentConfigurationError || hasRestaurantError || hasStripeError;

  if (order && restaurant && stripeObject) {
    return <Checkout conceptLabel={conceptLabel} order={order} restaurant={restaurant} stripeObject={stripeObject} />;
  }

  if (isPending) {
    return <PageLoader />;
  }

  return (
    <AppLayout title={t`Payment`} hasArrowBack url={`/menu/${restaurantPlatformId}`}>
      <Box p={6}>
        <Card>
          <Image src={checkoutImgSrc} margin="auto" width="144px" />
          <Heading as="h5" size="sm" textAlign="center" mb={6}>
            <Trans>Payment info</Trans>
          </Heading>
          <Stack gap={2}>
            {isError && (
              <Alert status="error" mt={4} fontSize="xs">
                <AlertIcon />
                <Trans>Sorry, we’re having trouble loading payment information. Please start your order again.</Trans>
              </Alert>
            )}
            <Button as={Link} to={restaurant ? `/${restaurant.locationUuid}` : '/'}>
              <Trans>Go to Home</Trans>
            </Button>
          </Stack>
        </Card>
      </Box>
    </AppLayout>
  );
};
