import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  List,
  ListItem,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { setContext } from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { omit, uniqueId } from 'lodash-es';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReadiedOrderSummary, useOrderQueryOptions, useValidateOrderQuery } from '@/api/gateway-click-collect/orders';
import { usePrepareWebOrderMutation } from '@/api/gateway-click-collect/orders/usePrepareWebOrderMutation';
import { Restaurant } from '@/api/gateway-click-collect/restaurants';
import CartItemCard from '@/components/CartItemCard';
import CtaButton from '@/components/CtaButton';
import { useCart } from '@/contexts/cart';
import { usePendingOrder } from '@/contexts/PendingOrderProvider';
import { formatCartForValidation, getCartPrice } from '@/helpers/cart.helpers';
import dataLayer from '@/helpers/dataLayer.helpers';
import { getPreOrderTimes, isRestaurantOpen } from '@/helpers/openingHours.helpers';

import CartDrawerFooter from './CartDrawerFooter';
import { PickupForm } from './PickupForm';
import { PickupOption, PickupTypeEnum } from './types';

export interface CartDrawerProps {
  restaurant: Restaurant;
}

const getDefaultPickupOption = (isRestaurantOpen: boolean, canPreOrder: boolean) => {
  if (isRestaurantOpen) {
    return { type: PickupTypeEnum.ASAP };
  }

  if (canPreOrder) {
    return { type: PickupTypeEnum.LATER };
  }

  return null;
};

export const CartDrawer = ({ restaurant }: CartDrawerProps) => {
  const initialFocusRef = useRef(null);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { pendingOrder, setPendingOrder } = usePendingOrder();
  const { cart, setCart, resetCart } = useCart();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { currencyCode, status, openingHours, timezone, languageCode, kitchenLabel } = restaurant;
  const preOrderTimes = getPreOrderTimes({ openingHours, timezone, status });
  const canPreOrder = !!preOrderTimes.length;
  const [pickupOption, setPickupOption] = useState<PickupOption | null>(() =>
    getDefaultPickupOption(isRestaurantOpen(status), canPreOrder)
  );
  const isCheckoutDisabled = pickupOption?.type === PickupTypeEnum.LATER && !pickupOption?.pickupTime;

  const { data: validPrice, isError: hasValidateError } = useValidateOrderQuery({
    options: {
      enabled: !!cart?.items?.length,
      retry: false,
      select: (validOrder) => validOrder.totalPrice,
    },
    requestParams: {
      cart: { ...formatCartForValidation(cart), couponCode: undefined },
    },
  });

  const price = validPrice ?? getCartPrice(cart?.items);

  const handlePickupTimeChange = (newPickupOption: PickupOption | null) => {
    setPickupOption(newPickupOption);

    if (newPickupOption?.type === PickupTypeEnum.LATER && newPickupOption?.pickupTime) {
      const [hour, minute, second] = newPickupOption.pickupTime.split(':');

      setCart({
        ...cart,
        pickupTime: dayjs().tz(timezone).set({ hour, minute, second }).format(),
      });
    } else {
      setCart({
        ...cart,
        pickupTime: undefined,
      });
    }
  };

  const removeCartItem = (itemIndex: number) => {
    const removedItem = cart.items[itemIndex];
    const items = cart.items.filter((_, index) => index !== itemIndex);

    setCart({ ...cart, items });
    dataLayer.logItemRemoved(cart.kitchenLabel, cart.conceptLabel, removedItem, 'online');

    if (!items.length) {
      closeDrawer();
    }
  };

  const {
    isError: hasPrepareError,
    isPending: isPreparingOrder,
    mutate: prepareOrder,
    reset: resetPrepare,
  } = usePrepareWebOrderMutation({
    onSuccess: (order) => {
      dataLayer.startCheckout({
        conceptLabel: cart.conceptLabel,
        kitchenLabel,
        order,
        orderChannel: 'online',
      });

      queryClient.setQueryData<ReadiedOrderSummary>(
        useOrderQueryOptions.queryKey({ orderUuid: order.uuid }),
        () => order
      );

      setContext('order', { ...omit(order, ['items']) });
    },
  });

  const handleSubmit = () => {
    prepareOrder(
      {
        cart: { ...formatCartForValidation(cart), orderUuid: pendingOrder },
      },
      {
        onSuccess: (order) => {
          setPendingOrder(order.uuid);
          navigate(`/checkout/${order.uuid}`);
        },
      }
    );
  };

  const closeDrawer = () => {
    resetPrepare();
    onClose();
  };

  const clearCart = () => {
    resetCart();
    closeDrawer();
  };

  const isCartInvalid = hasPrepareError || hasValidateError;
  const numberOfItems = cart?.items?.reduce((quantityAcc, item) => quantityAcc + item.quantity, 0);

  const responsiveDrawerProps = useBreakpointValue<Pick<DrawerProps, 'size' | 'placement'>>({
    base: { placement: 'bottom', size: 'xl' },
    md: { placement: 'right', size: 'md' },
  });

  return (
    <>
      {numberOfItems > 0 && (
        <CtaButton onClick={onOpen}>
          <Trans>My cart ({numberOfItems})</Trans>
        </CtaButton>
      )}
      <Drawer {...responsiveDrawerProps} initialFocusRef={initialFocusRef} isOpen={isOpen} onClose={closeDrawer}>
        <DrawerOverlay />
        <DrawerContent borderTopRadius={{ base: '3xl', md: 'none' }} height={{ base: '90vh', md: '100vh' }}>
          <DrawerCloseButton top={4} />
          <DrawerHeader borderBottomWidth="2px" textAlign="center">
            <Trans>My cart</Trans>
          </DrawerHeader>
          <DrawerBody>
            <PickupForm
              onChange={handlePickupTimeChange}
              openingHours={openingHours}
              pickupOption={pickupOption}
              status={status}
              timezone={timezone}
            />
            <hr style={{ margin: '1rem -24px' }} />
            <Text fontWeight="500" fontSize="md">
              <Trans>Your items</Trans>
            </Text>
            <List>
              {cart?.items?.map((item, index) => (
                <ListItem
                  borderBottomWidth={cart.items.length - 1 !== index ? '1px' : undefined}
                  key={uniqueId(item.menuElementUuid)}
                >
                  <CartItemCard
                    handleRemoveItemCart={() => removeCartItem(index)}
                    itemCart={item}
                    currencyCode={currencyCode}
                  />
                </ListItem>
              ))}
            </List>
          </DrawerBody>
          <CartDrawerFooter
            languageCode={languageCode}
            cart={cart}
            currencyCode={currencyCode}
            isError={isCartInvalid}
            isLoading={isPreparingOrder}
            isSubmitDisabled={isCheckoutDisabled}
            onClose={closeDrawer}
            onErrorClick={clearCart}
            onSubmit={handleSubmit}
            price={price}
            ref={initialFocusRef}
          />
        </DrawerContent>
      </Drawer>
    </>
  );
};
