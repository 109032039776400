import { Stack, Text } from '@chakra-ui/react';
import { Trans } from '@lingui/macro';
import { Navigate, useParams } from 'react-router-dom';

import { ReadiedOrderSummary, useOrderQuery } from '@/api/gateway-click-collect/orders';
import { useRestaurantMenuQuery, useRestaurantQuery } from '@/api/gateway-click-collect/restaurants';
import IntercomLauncher from '@/components/IntercomLauncher';
import OrderDetailsCard from '@/components/Order/OrderDetailsCard';
import { OrderInfoCard } from '@/components/Order/OrderInfoCard';
import OrderStatusCard from '@/components/Order/OrderStatusCard';
import PageLoader from '@/components/PageLoader';
import { getMenuPicturesMap } from '@/helpers/menu.helpers';
import usePageViewTracker from '@/hooks/usePageViewTracker';
import { AppLayout } from '@/layouts/AppLayout';
import { OrderReceiptButton } from '@/routes/OrderSummaryPage/OrderReceiptButton';

interface OrderSummaryProps {
  order: ReadiedOrderSummary;
}

const OrderSummary = ({ order }: OrderSummaryProps) => {
  const { restaurantPlatformId, number, couponInfo, readyAt, uuid, status } = order;
  const canAskForReceipt = !couponInfo?.couponAccepted;

  const { data: menu } = useRestaurantMenuQuery({
    requestParams: { restaurantPlatformId },
  });

  const { data: restaurant } = useRestaurantQuery({
    requestParams: { restaurantPlatformId },
  });

  const orderItems = menu
    ? order.items.map((item) => {
        const menuItemsPicturesMap = getMenuPicturesMap(menu);

        return {
          uuid: item.uuid,
          quantity: item.quantity,
          label: item.label,
          modifiers:
            item.modifiers?.map((modifier) => {
              return {
                uuid: modifier.uuid,
                quantity: modifier.quantity,
                label: modifier.label,
                modifiers: [],
              };
            }) ?? [],
          imageUrl: menuItemsPicturesMap[item.uuid],
        };
      })
    : undefined;

  return (
    <AppLayout title={<Trans>Your order</Trans>} showLogo>
      <Stack p={3} gap={3} pb={6}>
        <OrderStatusCard number={number} status={status} readyAt={readyAt} />
        <IntercomLauncher />
        {restaurant && (
          <OrderInfoCard
            kitchenName={restaurant.restaurantLabel}
            kitchenAddress={restaurant.address}
            kitchenAccessInstructions={restaurant.kitchenInstructions}
            pickupTime={readyAt}
          />
        )}
        {canAskForReceipt ? (
          <OrderReceiptButton orderUuid={uuid} />
        ) : (
          <Text color="#979797" textAlign="center">
            <Trans>To obtain a receipt, please contact support@taster.com</Trans>
          </Text>
        )}
        {orderItems && <OrderDetailsCard items={orderItems} />}
      </Stack>
    </AppLayout>
  );
};

export const OrderSummaryPage = () => {
  const { orderUuid } = useParams() as { orderUuid: string };
  usePageViewTracker();

  const { data: order, isPending } = useOrderQuery({
    requestParams: {
      orderUuid,
    },
  });

  if (order) {
    return <OrderSummary order={order} />;
  }

  if (isPending) {
    return <PageLoader />;
  }

  return <Navigate to={`/notFound`} />;
};
